.intro {
    display: flex;
    height: 77vh;
    margin-top: 6rem;

}

.l-side {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
}

.i-name {
    display: flex;
    flex-direction: column;
}

.i-name>:nth-child(1) {
    color: var(--black);
    font-weight: bold;
    font-size: 3rem;
}

.i-name>:nth-child(2) {
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}


.i-name>:nth-child(3){
font-weight: 100;
color: var(--gray);
font-size: 14px;
}

.btn-hire {
    width: 6rem;
    height: 2rem;
    
}

.icons {
    display: flex;
    margin-top: 3rem;
    gap: 0rem;
}

.icons img {
    transform: scale(0.5);
}

.icons img:hover {
    cursor: pointer;
}

.r-side {
    position: relative;
    flex: 1;
}

.r-side>*{
    position: absolute;
    z-index: 1;
}

.r-side>:nth-child(1){
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}

.r-side>:nth-child(2){
    transform: scale(0.67);
    left: -3rem;
    top: -4.6rem;
}

/*profile image */
.r-side>:nth-child(3){
    transform: scale(0.4);
    top: -9rem;
    border-radius: 90px;
    max-width: 100%;
    height: auto;
    border: none;
    outline: none;
}

.r-side>:nth-child(4){
    transform: scale(0.6);
    top: -19%;
    left: -24%;
    border-radius: 50%;
    padding: 0;
}

.blur {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff;
    filter: blur(72px);
    z-index: -9;
    top: -18%;
    left: 56%;

}

@media screen and (max-width: 480px) {
    .intro {
        flex-direction: column;
        height: 64rem;
        gap: 1rem;
    }

    .r-side{
        transform: scale(0.8);
        left: -3rem;
    }

    .r-side .blur {
        display: none;
    }

    .floating-div:nth-of-type(1){
top: -7rem!important;
    }

    .r-side>:nth-child(3){
        transform: scale(0.9);
        top: 2rem;
        left: 6rem;
        
    }
}











