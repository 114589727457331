:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.navbar{
    height: 10vh;
    display: flex;
    justify-content: space-between;
}

.left-side{
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.left-side .name{
    font-size: 1.3rem;
    font-weight: bold;
}

.right-side {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    justify-content: center;
    font-weight: 400;
    margin-right: 4rem;
}

.list {
    flex: 10;
}

.right-side ul {
    display: flex;
    gap: 2rem;
    list-style: none;
    
}

.right-side ul li{
    text-decoration: none;
    font-size: 20px;
    font-weight: normal;
    cursor: pointer;
}

.right-side ul li:hover {
color: var(--orange);

}

.n-btn{
    flex: 2;
}

@media screen and (max-width: 480px) {
    .list {
        display: none;
    }

    .n-btn{
        display: none;
    }
}



